import React, { useEffect, useState, useMemo } from "react";

import FormStepHeader from "../../../components/Common/FormStepHeader";

import ApiService from "infrastructure/Services/ApiService";

import {
  Table,
  Col,
  Input,
  Row,

} from "reactstrap";
import Loader from "components/Common/Loader";


export default ({ booking, onUpdateValues, currentStep, index, onBookingSaved, error, setError }) => {
    const save = () => {   
      setError(false);
      if (booking.id){
        ApiService.put(`bookings/${booking.type}/${booking.id}`, booking)
        .then(resp => {
            if (resp && resp.id && resp.id != '00000000-0000-0000-0000-000000000000'){
              onBookingSaved();
            }else{
                setError(true);
            }
          })
      } else{  
        ApiService.post(`bookings/${booking.type}`, booking)
        .then(resp => {
          if (resp && resp.id && resp.id != '00000000-0000-0000-0000-000000000000'){
            onBookingSaved();
          }else{
              setError(true);
          }
        })
      }
    }

  useEffect(() => {
    
    if (currentStep === index) save();    
  }, [currentStep]);


  let className = `step`

  if (currentStep < index){
    className += ` next`;
  }else if (currentStep > index){    
    className += ` prev`;
  }else{
    className += ` current`;

  }


  return (
    <div className={className}>
      <FormStepHeader
          sectionName={`Booking Details`}
          title={`Attachments`}
          icon={`flask`}
        />
      <Row><Col className="text-center">
        {!error ? <Loader /> : <div className="alert alert-danger">
          <p className="mb-0">There was an error saving your booking. Please check your details and try again.</p>

        </div>}
      </Col></Row>
    </div>
  );
};

