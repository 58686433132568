import React, { useState, useEffect } from "react";
import moment from "moment";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import "./DateSelector.scss";
export default ({ onDateChange, type, startDate }) => {
  const pickerType = type || 'day';
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [dates, setDates] = useState(null);

  useEffect(() => {

    setDate();

  }, []);
  useEffect(() => {

    setDate();

  }, [startDate]);
  const setDate = () => {
    var startOfWeek = startDate ? new moment(startDate).startOf('week') : new moment().startOf("week");

    if (pickerType === `week`) {
      var endOfWeek = startDate ? new moment(startDate).endOf('week') : new moment().endOf("week");
      setDates({
        startDate: startOfWeek,
        endDate: endOfWeek,
      });
    } else {
      setDates({
        startDate: startOfWeek.startOf("week")
      });
    }
  }

  const handleDateChange = (d) => {
    const start = new moment(d).startOf("week");
    const newDates = {
      startDate: start,
    };
    let end = null;
    if (pickerType === `week`) {
      end = new moment(d).endOf("week");
      newDates.endDate = end;
    }


    setDates(newDates);
    setOpenDatePicker(false);
    onDateChange(start, end);
  };

  const prevWeek = () => {

    const start = new moment(startDate).startOf("week").add(-1, 'weeks');
    handleDateChange(start);
  }
  const nextWeek = () => {
    const start = new moment(startDate).startOf("week").add(1, 'weeks');
    handleDateChange(start);
  }

  return (
    <>
      {dates && (
        <>
          <h6 className="text-uppercase ls-1 mb-1">Dates</h6>
          <div className="">
            <img
              style={styles.icon}
              className="mr-2 cursor-pointer"
              onClick={prevWeek}
              src={require(`line-awesome/svg/arrow-left-solid.svg`)}
            ></img>
            <h2
              className="mb-0 cursor-pointer d-inline float-left"
              onClick={() => setOpenDatePicker(true)}
            >
              {dates.startDate.format("LL")}{dates.endDate && <> - {dates.endDate.format("LL")}</>}

            </h2>

            <span className="text-muted float-left cursor-pointer mx-2" id="PopoverLegacy">
              <img
                style={styles.icon}
                src={require(`line-awesome/svg/calendar.svg`)}
              ></img>
            </span>

            <img
              className="cursor-pointer"
              style={styles.icon}
              src={require(`line-awesome/svg/arrow-right-solid.svg`)}
              onClick={nextWeek}
            ></img>
          </div>
          <UncontrolledPopover
            trigger="legacy"
            placement="bottom"
            target="PopoverLegacy"
            isOpen={openDatePicker}
            toggle={() => setOpenDatePicker((prevstate) => !prevstate)}
          >
            <PopoverBody>
              <Datetime
                className={`date-picker-${pickerType}`}
                renderInput={null}
                timeFormat={false}
                input={false}
                onChange={handleDateChange}
              />
            </PopoverBody>
          </UncontrolledPopover>

        </>
      )}
    </>
  );
};


const styles = {
  icon: { width: 28, float: `left`, display: `block`, float: `left` }
};